import { Injectable } from "@angular/core";
import {
  OrganizationForOwnerDto,
  OrganizationServiceProxy,
} from "@shared/service-proxies/Organization-client";
import { AbstractCacheService } from "./abstract-cache.service";
import { DataChangedBroadcastService } from "./data-changed-broadcast.service";

@Injectable({
  providedIn: "root",
})
export class OrganizationsCacheService extends AbstractCacheService<
  OrganizationForOwnerDto,
  string
> {
  constructor(
    private service: OrganizationServiceProxy,
    private dataChangeSignal: DataChangedBroadcastService
  ) {
    super();

    this.dataChangeSignal.OwnerOrganizationChanged$.subscribe(() =>
      this.forceReload()
    );
  }

  requestAll() {
    return this.service.getAll();
  }

  requestOne(_id: string) {
    // not implemented
    return null;
  }
}
